<template>
  <v-ons-splitter>
    <v-ons-splitter-side
      width="70%"
      collapse=""
      side="left"
      :open.sync="openSide"
    >
      <v-ons-page>
        <v-ons-card>
          <div style="display:table;margin:0 auto;">
            <img class="avatar" :src="userProfile.photoURL" />
            <v-ons-icon icon="md-upload" @click="this.chooseFile"></v-ons-icon>
          </div>
          <input
            type="file"
            class="form-control"
            id="elemFileUpload"
            @change="fileUploadChange"
            hidden
          />
          <v-ons-list modifier="inset">
            <v-ons-list-item
              tappable
              @click="this.showPrompt"
              style="height:30px; min-height: 30px"
            >
              <div
                style="color:black;font-size:large;font-weight:bold;text-transform:capitalize;"
              >
                {{ currentUser.displayName }}
                <!--{{currentUser.displayName}} -->
              </div>
            </v-ons-list-item>
            <v-ons-list-item style="line-height:1.0">
              <div style="color:blue;font-size:small;">
                {{ currentUser.email }}
              </div>
            </v-ons-list-item>
          </v-ons-list>
          <v-ons-list>
            <v-ons-list-item @click="RefreshMap()">
              <div class="right">
                <v-ons-icon icon="fa-sync" class="list-item__icon">
                </v-ons-icon>
              </div>
              <div class="center">
                Refresh Map
              </div>
            </v-ons-list-item>
            <v-ons-list-item @click="router.push('/logout')">
              <div class="right">
                <v-ons-icon icon="fa-sign-out-alt" class="list-item__icon">
                </v-ons-icon>
              </div>
              <div class="center">
                Logout
              </div>
            </v-ons-list-item>
            <v-ons-list-item
              @click="
                $ons.notification.toast('TerratonePull Version 2.0.3m', {
                  timeout: 2000,
                })
              "
            >
              <div class="right">
                <v-ons-icon icon="fa-info" class="list-item__icon">
                </v-ons-icon>
              </div>
              <div class="center">
                About
              </div>
            </v-ons-list-item>
          </v-ons-list>
        </v-ons-card>
      </v-ons-page>
    </v-ons-splitter-side>

    <v-ons-splitter-content>
      <v-ons-page id="olmap2">
        <div ref="mapView" class="map-view" id="mapView">
          <!-- Popup -->
          <div ref="popup" class="ol-popup">
            <a @click="popupcloser" class="ol-popup-closer"></a>
            <div class="ol-popup-content" v-html="popupContent"></div>
            <div id="popupbottombar">
              <div style="float: left;font-size:20px">
                <i
                  class="fas fa-street-view"
                  style="font-size:20px; padding-left:0px;"
                  @click="showStreetviewPage()"
                ></i>
              </div>
              <div
                style="float: right; font-size: 18px"
                v-bind:class="[
                  isBookmarked ? 'fa fa-bookmark' : 'far fa-bookmark',
                ]"
                @click="toggleClass()"
              ></div>
            </div>
          </div>
        </div>
        <!-- Right sidebars -->
        <div id="summarysidebar" class="sidebar right" @click="clickSumSidebar">
          <div id="db1"><Pie :chart-data="chartData" /></div>
          <div id="ChartSection">
            <v-ons-row>
              <v-ons-col>
          <div id="plot_div1"></div>
              </v-ons-col>
              <v-ons-col>
          <div id="plot_div2"></div>
              </v-ons-col>
            </v-ons-row>
          </div>
          <div id="db3"><div v-html="html_mortgageSummary"></div></div>
          <div id="db2"><div v-html="html_mortgageLegend"></div></div>
        </div>
        <v-ons-speed-dial
          id="sd-menu"
          position="bottom right"
          direction="up"
          :open.sync="toggle"
          @click="toggle = !toggle"
        >
          <v-ons-fab>
            <i :class="[toggle ? 'fa-times' : 'fa-plus', 'fa']"></i>
          </v-ons-fab>
          <v-ons-speed-dial-item>
            <v-ons-icon icon="md-comment" @click="showNoteForm()"></v-ons-icon>
          </v-ons-speed-dial-item>
          <v-ons-speed-dial-item>
            <v-ons-icon
              icon="md-bookmark"
              @click.prevent="router.push('bookmark')"
            ></v-ons-icon>
          </v-ons-speed-dial-item>
        </v-ons-speed-dial>
        <v-ons-button
          modifier="quiet"
          class="mainmenu"
          :visible="true"
          @click="openSide = true"
        >
          <v-ons-icon
            style="color: black"
            size="32px"
            icon="fa-caret-square-right"
          ></v-ons-icon>
        </v-ons-button>
        <v-ons-dialog cancelable :visible.sync="createpostVisible">
          <createpost
            v-bind:ownerinfo="ownerinfo"
            v-bind:propinfo="propinfo"
            v-bind:selectedCentre="selectedCentre"
          ></createpost>
        </v-ons-dialog>
      </v-ons-page>
    </v-ons-splitter-content>
  </v-ons-splitter>
</template>
<script>
import "ol/ol.css";
import { Map, View } from "ol";
import * as olControl from "ol/control";
import * as olProj from "ol/proj";
import "../../node_modules/ol-geocoder/dist/ol-geocoder.min.css";
import Geocoder from "ol-geocoder";
import { Style } from "ol/style";
import { Fill, Stroke, Icon } from "ol/style";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import { Vector } from "ol/source";
import VectorLayer from "ol/layer/Vector";
import Point from "ol/geom/Point";
import Control from "ol/control/Control";
import { circular } from "ol/geom/Polygon";
import { fromLonLat } from "ol/proj";
import { containsCoordinate, containsExtent } from "ol/extent";
import Overlay from "ol/Overlay";
import Select from "ol/interaction/Select";

import "ol-ext/control/Timeline.css";
import Timeline from "ol-ext/control/Timeline";

import fb from "../firebase";

//for API call
import axios from "axios";

import mapconfig from "../config/mapconfig";

import MapFunctions from "../MapFunctions";
import recentTransactions from "../recent-transactions";
import landMerger from "../land-merger";

//import mortgage from "../mortgage"

// Import the EventBus we just created.
import EventBus from "../config/event-bus";

import { mapState } from "vuex";

import router from "../router";
import createpost from "../components/createpost";
import state from "../state";
import arr from "../config/arr-stat.js";

import { Bar, Pie } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

import * as dfd from "danfojs";
import { readCSV, DataFrame } from "danfojs";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale
);

const proxyurl1 = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)
const proxyurl2 = "https://index2.laurel.workers.dev/?";

const Perry_extent = [
  -13708573.09907316,
  6317899.993763422,
  -13706121.72827147,
  6319568.366776173,
];
//Map variables
let map = null;
// Variables for Overlay new
let popup;
export default {
  mixins: [MapFunctions],
  data() {
    return {
      //
      popupContent: "",
      isBookmarked: false,
      toggle: false,
      router: router,
      createpostVisible: false,
      openSide: false,
      ownerinfo: null,
      propinfo: null,
      selectedParcel: null,
      selectedCentre: null,
      name: "",
      lonlat: null,
      selectedAddress: null,
      selectedParcel_id: null,
      bookmarksFeatures: null,
      newPhoto: null,
      selectedAssetType: [
        "APT",
        "HOT",
        "ICI",
        "IND",
        "OFF",
        "RLN",
        "RET",
        "OTH",
      ],
      mTotalPoints: 0,
      mSumPrice: 0,
      mSumLoan: 0,
      mAvgPrice: 0,
      mAvgLoan: 0,
      mAvgLTV: 0,
      mMaturity: "",
      html_mortgageSummary: "",
      html_mortgageLegend: "",
      tline_sDate: null,
      tline_eDate: null,
      tline_interval: "90d",
      chartData: {
        labels: ["Residental Land", "Retail", "Office", "ICI"],
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
            data: [20, 80, 10, 20],
          },
        ],
      },
      featureList: [],
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser", "bookmarks"]),
  },
  watched: {},
  components: {
    createpost: createpost,
    Bar,
    Pie,
  },
  methods: {
    chooseFile() {
      this.$ons.notification.alert({
        message:
          "Please select an image file with 200*200 pixel for best display.",
        callback: function() {
          document.getElementById("elemFileUpload").click();
        },
      });
    },
    fileUploadChange(e) {
      let file = e.target.files[0];
      let that = this;
      console.log("file", file);
      let filePic = file;
      let fileSize = file.size;
      if (fileSize > 1024 * 1024) {
        that.$ons.notification.alert("File size cannot exceed 1M!");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(filePic);
      reader.onload = function(e) {
        var data = e.target.result;
        var image = new Image();
        image.onload = function(e) {
          var width = image.width;
          var height = image.height;
          console.log("imagesize:", width, height);
          if (width == 200 || height == 200) {
            that.newPhoto = file;
            that.updateProfile();
          } else {
            that.$ons.notification.alert("Image size should be:200*200!");
            return;
          }
        };
        image.src = data;
      };
    },
    checkBookmarked: function() {
      var abookmark = null;
      console.log("selectedParcel_id", this.selectedParcel_id);
      console.log("bookmarks", this.bookmarks);
      abookmark = this.bookmarks.find(
        (bookmark) => bookmark.data.parcel_id == this.selectedParcel_id
      );
      console.log("abookmark", abookmark);
      this.isBookmarked = abookmark;
    },
    showPrompt() {
      let that = this;
      this.$ons.notification
        .prompt({
          message: "Enter your new user name:",
          title: "Update Profile",
          cancelable: true,
          buttonLabels: ["Cancel", "OK"],
        })
        .then(function(input) {
          if (input) {
            console.log("input:", input);
            that.name = input;
            that.updateProfile();
          }
        });
    },
    updateProfile() {
      let file = this.newPhoto;
      let extension = file.name.split(".").pop();
      let newfilename = this.currentUser.uid + "." + extension;
      let newfile = new File([file], newfilename);
      console.log("newfilename", newfile.name);
      var aDownLoadURL;
      var uploadTask = fb.storage
        .ref()
        .child(`images/${newfile.name}`)
        .put(newfile);
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("File available at", downloadURL);
            aDownLoadURL = downloadURL;
            this.userProfile.photoURL = aDownLoadURL;
            console.log("userProfile", this.userProfile);
            this.$store.dispatch("updateProfile", this.userProfile);

            this.name = "";
          });
        }
      );

      // {
      //name: this.name !== '' ? this.name : this.userProfile.name,
      //title: this.title !== '' ? this.title : this.userProfile.title
      //  })
    },
    RefreshMap() {
      console.log("Refresh Map");
      this.$ons.notification.toast("Refreshing the map...", {
        timeout: 1000,
        animation: "fall",
      });
      map.updateSize();
      map.renderSync();
    },
    toggleClass: function(event) {
      this.isBookmarked = !this.isBookmarked;
      this.updateBookmark();
    },
    deleteParcelonBookmarkLayer(bookmarkParcel_id) {
      map.getLayers().forEach(function(lyr) {
        console.log(lyr.get("name"));
        if (lyr.get("name") === "Bookmarks") {
          //lyr.getSource().addFeatures([feature])
          console.log("bookmarkParcel_id", bookmarkParcel_id);
          console.log("all features:", lyr.getSource().getFeatures());
          var searchFeature = lyr.getSource().getFeatureById(bookmarkParcel_id);
          console.log("searchFeature", searchFeature);
          lyr.getSource().removeFeature(searchFeature);
        }
      });
    },
    updateBookmark() {
      // create bookmark
      console.log("uid", this.currentUser.uid);
      console.log("bookmark List", this.bookmarks);
      var selectedCoordinates = JSON.stringify(
        this.selectedParcel.geometry.coordinates
      );
      console.log("coordinates", selectedCoordinates);
      var that = this;
      var userRef = fb.usersCollection;

      var bookmarkRef = userRef
        .doc(this.currentUser.uid)
        .collection("bookmarks")
        .where("parcel_id", "==", this.selectedParcel_id);
      bookmarkRef.get().then((querySnapshot) => {
        if (querySnapshot.empty) {
          // Add
          //userRef.doc(this.currentUser.uid).collection("bookmarks")
          userRef
            .doc(this.currentUser.uid)
            .collection("bookmarks")
            .add({
              createdOn: new Date(),
              coordinates: selectedCoordinates,
              address: this.selectedAddress,
              pid: this.selectedParcel.properties.PID,
              parcel_id: this.selectedParcel_id,
              lonlat: this.lonlat,
              centre: this.selectedCentre.flatCoordinates,
            })
            .then((ref) => {
              var feature = new Feature(new Point(fromLonLat(this.lonlat)));
              console.log(
                "add single feature polygon coordinates",
                this.selectedParcel.geometry.coordinates
              );

              var polygon = new Polygon(
                this.selectedParcel.geometry.coordinates
              );
              var afeature = new Feature(polygon);
              afeature.setId(this.selectedParcel_id);

              map.getLayers().forEach(function(lyr) {
                console.log(lyr.get("name"));
                if (lyr.get("name") === "Bookmarks") {
                  //lyr.getSource().addFeatures([feature])
                  lyr.getSource().addFeature(afeature);
                  //lyr.visible=true;
                }
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
        //delete
        else {
          console.log("querySnapshot doc id", querySnapshot.docs[0].id);
          //querySnapshot.docs[0].delete()
          fb.usersCollection
            .doc(this.currentUser.uid)
            .collection("bookmarks")
            .doc(querySnapshot.docs[0].id)
            .delete();
          console.log("Parcel ID already bookmarked:", this.selectedParcel_id);
          var aselected_Parcel_id = this.selectedParcel_id;
          var select = new Select();
          select.getFeatures().clear();
          map.getLayers().forEach(function(lyr) {
            console.log(lyr.get("name"));
            if (lyr.get("name") === "Bookmarks") {
              //lyr.getSource().addFeatures([feature])
              console.log("aselected_Parcel_id", aselected_Parcel_id);
              console.log("all features:", lyr.getSource().getFeatures());
              var searchFeature = lyr
                .getSource()
                .getFeatureById(aselected_Parcel_id);
              console.log("searchFeature", searchFeature);
              lyr.getSource().removeFeature(searchFeature);
            }
          });
        }
      });
    },
    showStreetviewPage() {
      state.lonlat = this.lonlat;
      //console.log("lonlat in olmap2",this.lonlat)
      router.push("streetview");
    },
    showNoteForm() {
      this.createpostVisible = true;
    },
    init() {
      this.initMap();
      let that = this;
      //this.setupBookmarkLayer()
      map.getLayers().forEach(function(lyr) {
        //console.log(lyr.get('name'));
        if (lyr.get("name") === "Bookmarks") {
          //console.log("that.currentUser",that.currentUser)
          fb.usersCollection
            .doc(that.currentUser.uid)
            .collection("bookmarks")
            .get()
            .then((res) => {
              //console.log("bookmarks in olmap2",res)
              res.forEach(function(doc) {
                //console.log("doc.data.coordinates",JSON.parse(doc.data().coordinates))
                var polygon = new Polygon(JSON.parse(doc.data().coordinates));
                //console.log("polygon",polygon)
                var afeature = new Feature(polygon);
                //console.log("data parcel_id",doc.data().parcel_id)
                afeature.setId(doc.data().parcel_id);
                //console.log("afeature",afeature)
                lyr.getSource().addFeature(afeature);
              });
            });
        }
      });
      this.initPopup();
      //router.push({ name: "transaction" });
    },
    initMap() {
      console.log("map");
      map = new Map({
        target: this.$refs.mapView,
        layers: [
          mapconfig.streetmap,
          //mapconfig.googlemap,
          mapconfig.satellitemap,
          mapconfig.bcparcelmap,
          mapconfig.bookmarksmap,
          //mapconfig.indmap,
          //mapconfig.mortgagemap,
          mapconfig.mortgagevlayer,
        ],
        view: new View({
          center: olProj.transform(
            [mapconfig.x, mapconfig.y],
            "EPSG:4326",
            "EPSG:3857"
          ),
          zoom: mapconfig.zoom,
        }),
        controls: olControl.defaults({
          // Set to display OSM attributions on the bottom right control
          attributionOptions: {
            collapsible: true,
            collapsed: true,
          },
          zoom: false,
        }),
      });
      let that = this;
      mapconfig.mortgagevlayer.getSource().on("change", function(evt) {
        const source = evt.target;
        if (source.getState() === "ready") {
          var numFeatures = source.getFeatures();
          that.featureList = [];

          //console.log('mortgage vector layer Feature List:',numFeatures);
          /*
          numFeatures.forEach(function(f){
            var numProperties=f.getProperties();
            //console.log("properties:",numProperties)
            that.featureList.push(numProperties)
            
          })
          console.log("Feature List:",that.featureList.length)
        
          var df = new dfd.DataFrame(that.featureList)
          //let test=df.column("InvNum").str.slice(3, 6).print()
          df.addColumn("AssetType", df.column("InvNum").str.slice(3, 6), { inplace: true })
          df.groupby(["City"]).agg({"City":"count"}).print()
          df.print()
          */
        }
      });

      function ToggleSatLayer() {
        map.getLayers().forEach(function(lyr) {
          // console.log(lyr.get('name'));
          if (lyr.get("name") === "GSatellite") {
            var is_visible = lyr.get("visible");
            lyr.setVisible(!is_visible);
          }
        });
      }
      // Satellite Toggle Button
      const satellite = document.createElement("div");
      satellite.className = "ol-control ol-unselectable satellite";
      satellite.innerHTML = '<button><i class="fa fa-satellite"></i></button>';
      satellite.addEventListener("click", function satelliteclick() {
        ToggleSatLayer();
      });

      map.addControl(
        new Control({
          element: satellite,
        })
      );
      function ToggleBkLayer() {
        map.getLayers().forEach(function(lyr) {
          // console.log(lyr.get('name'));
          if (lyr.get("name") === "Bookmarks") {
            var is_visible = lyr.get("visible");
            lyr.setVisible(!is_visible);
          }
        });
      }
      // Bookmark Toggle Button
      const bookmark = document.createElement("div");
      bookmark.className = "ol-control ol-unselectable bookmark";
      bookmark.innerHTML = '<button><i class="fa fa-bookmark"></i></button>';
      bookmark.addEventListener("click", function bookmarkclick() {
        ToggleBkLayer();
      });

      map.addControl(
        new Control({
          element: bookmark,
        })
      );

      if (navigator.geolocation) {
        //navigator.permissions.query({name:'geolocation'}).then(function(result){
        //  console.log("geolocation permission:",result.state)
        //})

        const locate = document.createElement("div");
        locate.className = "ol-control ol-unselectable locate";
        locate.innerHTML =
          '<button><i class="fa fa-location-arrow"></i></button>';
        locate.addEventListener("click", function locateclick() {
          // For Location Checking
          const source = new Vector();
          const layer = new VectorLayer({
            source: source,
            visible: false,
            zoom: 17,
          });
          map.addLayer(layer);
          navigator.geolocation.getCurrentPosition(
            function(pos) {
              const coords = [pos.coords.longitude, pos.coords.latitude];
              source.clear(true);
              source.addFeatures([new Feature(new Point(fromLonLat(coords)))]);
              map.getView().fit(source.getExtent(), {
                maxZoom: 17,
                duration: 500,
              });
            },
            function(error) {
              alert(`ERROR: ${error.message}`);
            },
            {
              enableHighAccuracy: true,
            }
          );
        });
        map.addControl(
          new Control({
            element: locate,
          })
        );
      }
      //  Add Geocoder
      var provider = BCAddressFind({
        url: "https://geocoder.api.gov.bc.ca/addresses.geojson",
      });

      //Instantiate with some options and add the Control
      var geocoder = new Geocoder("nominatim", {
        provider: provider,
        targetType: "text-input",
        lang: "en-US",
        placeholder: "Search by address",
        limit: 8,
        autoComplete: true,
        autoCompleteMinLength: 8,
        keepOpen: false,
        preventDefault: false,
        deug: true,
      });

      map.addControl(geocoder);

      recentTransactions.createLayer(
        map,
        "mapView",
        "recentTrans",
        "recentTrans-filter"
      );
      landMerger.addButtonTo("sd-menu"); // add multiple-lands-selection button to the sd-menu

      // mortgage.createLayer(map, "mapView", "mortgage", "mortgage-filter");
      /**
       * Custom provider for BC Address for full address search of British Columbia.
       * Factory function which returns an object with the methods getParameters
       * and handleResponse called by the Geocoder
       */
      function BCAddressFind(options) {
        var url = proxyurl2 + options.url;
        return {
          /**
           * Get the url, query string parameters and optional JSONP callback
           * name to be used to perform a search.
           * @param {object} options Options object with query, key, lang,
           * countrycodes and limit properties.
           * @return {object} Parameters for search request
           */
          getParameters: function(opt) {
            return {
              url: url,
              //callbackName: 'callback',
              params: {
                //  q: opt.query,
                addressString: opt.query,
              },
            };
          },
          /**
           * Given the results of performing a search return an array of results
           * @param {object} data returned following a search request
           * @return {Array} Array of search results
           */
          handleResponse: function(results) {
            // The API returns a GeoJSON FeatureCollection
            if (results && results.features && results.features.length) {
              return results.features.map(function(feature) {
                //     console.log(results);

                return {
                  lon: feature.geometry.coordinates[0],
                  lat: feature.geometry.coordinates[1],
                  address: {
                    // Simply return a name in this case, could also return road,
                    // building, house_number, city, town, village, state,
                    // country
                    name: feature.properties.fullAddress,
                  },
                  bbox: feature.bbox,
                };
              });
            } else {
              return;
            }
          },
        };
      }

      //Listen when an address is chosen
      geocoder.on("addresschosen", function(evt) {
        /* eslint-disable no-console */
        console.log("geocoder:" + evt.address.formatted);
        /* eslint-enable no-console */
        //geocoderSource.clear();
        //geocoderSource.addFeature(evt.feature); // add only the last one
        //BySearch = true;
        //GetInfoFromSelected(evt.coordinate);

        that.MapOnClick(evt.coordinate);
        function remove_search_pin() {
          var remove_layer_name = "geocoder-layer";
          var layers_to_remove = [];
          map.getLayers().forEach(function(layer) {
            var layer_name = layer.getProperties().name;
            if (layer_name && layer_name.match(remove_layer_name)) {
              layers_to_remove.push(layer);
            }
          });
          for (var i = 0; i < layers_to_remove.length; i++) {
            map.removeLayer(layers_to_remove[i]);
          }
        }
        remove_search_pin();
      });
      map.on("singleclick", (evt) => {
        //console.log("evt",evt)
        var clickedFeature = map.forEachFeatureAtPixel(evt.pixel, function(
          feature
        ) {
          return feature;
        });
        console.log("clickedFeature:", clickedFeature);
        if (clickedFeature && clickedFeature.featureType) {
          switch (clickedFeature.featureType) {
            case recentTransactions.featureTypeMarker:
            case recentTransactions.featureTypeCluster:
              if (!this.reportVueMounted) {
                // including when this.reportVueMounted is undefined
                EventBus.$on("realnetReportMounted", () => {
                  EventBus.$emit(
                    "get_transactions",
                    recentTransactions.getRecentTransactions(clickedFeature)
                  );
                });
                this.reportVueMounted = true;
              }
              EventBus.$emit(
                "get_transactions",
                recentTransactions.getRecentTransactions(clickedFeature)
              );
              router.push({ name: "transaction" });
              break;
            default:
              console.log("Clicked on", clickedFeature.featureType);
          }
        } else if (clickedFeature) {
          let InvNum = clickedFeature.get("AssetType");
          let AssetType = "";
          switch (InvNum) {
            case "APT":
              AssetType = "Apartment";
              break;
            case "HOT":
              AssetType = "Hotel";
              break;
            case "ICI":
              AssetType = "ICI Land";
              break;
            case "IND":
              AssetType = "Industrial";
              break;
            case "OFF":
              AssetType = "Office";
              break;
            case "RET":
              AssetType = "Retail";
              break;
            case "RLN":
              AssetType = "Residential Land";
              break;
            case "OTH":
              AssetType = "Others";
              break;
            default:
              AssetType = "Others";
              break;
          }
          let TName = clickedFeature.get("TName");
          let Lender = clickedFeature.get("Lender");
          let MAmt = clickedFeature.get("MAmt");
          let Price = clickedFeature.get("Price");
          let LTV = ((MAmt / Price) * 100).toFixed(1) + "%";

          let MatDate = new Date(
            clickedFeature.get("MatDate")
          ).toLocaleDateString();
          let TDate = new Date(
            clickedFeature.get("TDate")
          ).toLocaleDateString();
          let Borrower = clickedFeature.get("Buyer");
          let Address = clickedFeature.get("Address");

          LTV = typeof LTV === "undefined" ? "" : LTV;
          MAmt = numFormatter(MAmt);
          MAmt = typeof MAmt === "undefined" ? "" : "$" + MAmt;
          //MAmt="$" + MAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          Price = numFormatter(Price);
          Price = typeof Price === "undefined" ? "" : "$" + Price;
          //Price="$" + Price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          let apopupContent = `<div style='text-align: left;'><font size="2"  ><table class="popuptable">
  <caption>
  <p style='color:blue;'>${TName}</p>
  </caption>
    <tr  valign="top">
    <th width="25%">Address</th>
    <td colspan="2";width="75%">${Address}</td>
  </tr>
  <tr  valign="top">
    <th width="25%">Type</th>
    <td colspan="2";width="75%">${AssetType}</td>
  </tr>
  <tr valign="top">
    <th>Borrower</th>
    <td colspan="2">${Borrower}</td>
  </tr>
    <tr valign="top">
    <th>Lender</th>  
    <td colspan="2">${Lender}</td>      
  </tr> 
    <tr valign="top">
    <th>Maturity</th>
    <td colspan="2">${MatDate}</td>
  </tr>
  <tr >
  <th width="40%">Loan</th ><th width="40%">Price</th><th>LTV</th>
  </tr>
  <tr >
  <td width="40%">${MAmt}</td>  <td width="40%">${Price}</td> <td>${LTV}</td>
  </tr>
</table></font></div>`;
          this.popupContent = apopupContent;
          document.getElementById("popupbottombar").style.visibility = "hidden";
          popup.setPosition(evt.coordinate);
        } else {
          var icoordinate = evt.coordinate;
          document.getElementById("popupbottombar").style.visibility =
            "visible";
          this.MapOnClick(icoordinate);
        }
      });

      // converts number to string representation with K and M.
      // toFixed(d) returns a string that has exactly 'd' digits
      // after the decimal place, rounding if necessary.
      function numFormatter(num) {
        if (num >= 999 && num < 1000000) {
          return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
        } else if (num >= 1000000) {
          return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
        } else if (num < 900) {
          return num; // if value < 1000, nothing to do
        }
      }

      this.html_mortgageSummary = `<div id='rcorners3' style='text-align: left;'><table class="popuptable">
  <tr >
    <td >Properties</td>
    <th>${that.mTotalPoints}</th>
  </tr>
  <tr >
    <tr >
    <td>Maturity Range</td>
    <th>${that.mMaturity}</th>
  </tr>
    <td>Total Loan $</td>
    <th >${that.mSumLoan}</th>
  </tr>
  <tr>
    <td >Total Price $</td>
    <th >${that.mSumPrice}</th>
  </tr>
  <tr >
  <td >Average Loan $</td >
  <th w>${that.mAvgLoan}</th> 
  </tr>
  <tr>
  <td >Average Price $</td>
  <th >${that.mAvgPrice}</th>
  </tr>
   <tr>
  <td>LTV %</td>
  <th>${that.mAvgLTV}</th>
  </tr>
    <tr>
  <td>LTAV %</td>
  <th>Not Available</th>
  </tr>
</table></font></div>`;

      function ToggleMortgageLayer() {
        map.getLayers().forEach(function(lyr) {
          // console.log(lyr.get('name'));
          if (lyr.get("name") === "mortgagevlayer") {
            var is_visible = lyr.get("visible");
            lyr.setVisible(!is_visible);
            if (is_visible == false) {
              mortLegend.style.display = "block";
              summarysidebar.style.display = "block";
              //summaryTable.style.display="block"
            } else if (is_visible == true) {
              mortLegend.style.display = "none";
              //summaryTable.style.display="none";
              summarysidebar.style.display = "none";
            }
          }
        });
      }

      // Mortgage Layer Toggle Button
      const mortgageLayer = document.createElement("div");
      mortgageLayer.className = "ol-control ol-unselectable mortgage";
      mortgageLayer.innerHTML =
        '<button><i class="fa fa-university"></i></button>';

      const mortLegend = document.createElement("div");
      mortLegend.className = "mortLegend";
      mortLegend.id = "rcorners2";

      mortLegend.innerHTML =
        '<div id="rcorners4" background-color:white;><table><tr style="valign:top"><td><img src="/img/APT.png" alt="APT" class="image" height="32"></td><td style="padding:.5rem">Apartment</td><td><input type="checkbox" id="aptCheckBox" value="APT" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/HOT.png" alt="HOT" class="image" height="32"></td><td style="padding:.5rem">Hotel</td><td><input type="checkbox" id="hotCheckBox" value="HOT" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/ICI.png" alt="ICI" class="image" height="32"></td><td style="padding:.5rem">ICI Land</td><td><input type="checkbox" id="iciCheckBox" value="ICI" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/IND.png" alt="IND" class="image" height="32"></td><td style="padding:.5rem">Industrial</td><td><input type="checkbox" id="indCheckBox" value="IND" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/OFF.png" alt="OFF" class="image" height="32"></td><td style="padding:.5rem">Office</td><td><input type="checkbox" id="offCheckBox" value="OFF" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/RLN.png" alt="RLN" class="image" height="32"></td><td style="padding:.5rem">Residential Land</td><td><input type="checkbox" id="rlnCheckBox" value="RLN" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/RET.png" alt="RET" class="image" height="32"></td><td style="padding:.5rem">Retail</td><td><input type="checkbox" id="retCheckBox" value="RET" checked="checked"></td></tr><tr style="valign:top"><td><img src="/img/NOTYPE.png" alt="OTH" class="image" height="32"></td><td style="padding:.5rem">Other</td><td><input type="checkbox" id="othCheckBox" value="OTH" checked="checked"></td></tr></table></div>';
      //comLegend.innerHTML="<p>Hello</p>"
      //mortgageLayer.appendChild(mortLegend);
      mortLegend.style.display = "none";
      this.html_mortgageLegend = mortLegend.innerHTML;
      mortgageLayer.addEventListener("click", function() {
        ToggleMortgageLayer();
        tline.toggle();
      });

      map.addControl(
        new Control({
          element: mortgageLayer,
        })
      );

      // Create Timeline control
      var tline = new Timeline({
        className: "ol-pointer",

        // features: [
        //   {
        //     text: 2023,
        //     date: new Date("2023/01/01"),
        //     endDate: new Date("2024/12/31"),
        //   },
        // ],
        interval: this.tline_interval,
        graduation: "month", // 'month',
        minDate: new Date("2022/11/01"),
        maxDate: new Date("2025/5/31"),
        getHTML: function(f) {
          return "Mortgage Maturity @ 2023-2024";
        },
        getFeatureDate: function(f) {
          return f.date;
        },
        endFeatureDate: function(f) {
          return f.endDate;
        },
      });
      map.addControl(tline);

      // Show features on scroll
      tline.on("scroll", function(e) {
        //console.log("e:",e)
        //var d = tline.roundDate(e.date, 'day')
        //$('.dateStart').text(d.toLocaleDateString(undefined, {year: 'numeric', month: 'short', day: 'numeric'}));
        // Filter features visibility
        //console.log("d:",Date.parse(d))

        var NoOfFeatures = mapconfig.mortgagevlayer.getSource().getFeatures()
          .length;
        //console.log("NoOfFeatures:",NoOfFeatures)
        var cachedFeatures = [];
        var cachedAttributes=[];
        var eStartDate = new Date(e.dateStart);
        var eEndDate = new Date(e.dateEnd);
        that.tline_sDate = eStartDate;
        that.tline_eDate = eEndDate;

        var sumPrice = 0;
        var sumLoan = 0;
        mapconfig.mortgagevlayer
          .getSource()
          .getFeatures()
          .forEach(function(f) {
            //console.log("feature of mlayer in tline:",new Date(f.get('MatDate')),eStartDate,eEndDate)
            var fMatDate = new Date(f.get("MatDate"));

            if (fMatDate > eStartDate && fMatDate < eEndDate) {
              if (that.selectedAssetType.includes(f.get("AssetType"))) {
                f.setStyle();
                if (!cachedFeatures.includes(f)) {
                  
                  sumPrice = sumPrice + f.get("Price");
                  sumLoan = sumLoan + f.get("MAmt");
                  cachedFeatures.push(f);
                  cachedAttributes.push({'AssetType':f.get("AssetType"),'Price':f.get("Price"),'Loan':f.get("MAmt"),'City':f.get("City"),'InvNum':f.get("InvNum")})
                }
              }
            } else {
              if (that.selectedAssetType.includes(f.get("AssetType"))) {
                f.setStyle([]);
              }
            }
            /*
      var dt = f.get('TDate') - e.date;
      if (Math.abs(dt) > 1000*3600*12) {
        f.setStyle([]);
      } else {
        f.setStyle();
      }*/
            //console.log("Total features selected:",i)
          });

        //console.log("cached Features:",cachedFeatures)
        //console.log("sum of cached Feature:",cachedFeatures.length,sumPrice,sumLoan,(sumPrice/cachedFeatures.length).toFixed(2),(sumLoan/cachedFeatures.length).toFixed(2),((sumLoan/sumPrice)*100).toFixed(2))
        that.mTotalPoints = cachedFeatures.length;
        //cachedAttributes=cachedAttributes.sort(function(a,b){return a.AssetType > b.AssetType})
        console.log("cachedAttributes:",cachedAttributes)
        var df = new dfd.DataFrame(cachedAttributes)
        //df.plot("plot_div1").pie({ config: { values: "Price", labels: "AssetType" } });
        //df.plot("plot_div2").pie({ config: { values: "Loan", labels: "AssetType" } });
        //let gp_df=df.groupby(["AssetType"]).agg({"AssetType":"count"}).print()
        let gp_df=df.groupby(["AssetType"]).count()
        gp_df.sortValues("Price_count",{ascending:false}).print()
        console.log("AssetType count:",gp_df)
        //gp_df.plot("plot_div1").pie({config:{values:"Price_count",labels:"AssetType"}})
       //df.describe().print()
       //df.head(5).print()
 
        that.mSumPrice = isNaN(sumPrice) ? "" : numFormatter(sumPrice);
        that.mSumLoan = isNaN(sumLoan) ? "" : numFormatter(sumLoan);
        that.mAvgPrice = (sumPrice / cachedFeatures.length).toFixed(2);
        that.mAvgLoan = (sumLoan / cachedFeatures.length).toFixed(2);
        that.mAvgLTV = ((sumLoan / sumPrice) * 100).toFixed(2);
        that.mMaturity =
          new Date(that.tline_sDate).toLocaleDateString("en-US", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }) +
          "-" +
          new Date(that.tline_eDate).toLocaleDateString("en-US", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          });

        that.mAvgPrice = isNaN(that.mAvgPrice)
          ? "$0"
          : "$" + numFormatter(that.mAvgPrice);
        that.mAvgLoan = isNaN(that.mAvgLoan)
          ? "$0"
          : "$" + numFormatter(that.mAvgLoan);
        that.mAvgLTV = isNaN(that.mAvgLTV) ? "0" : that.mAvgLTV;

        //console.log("mAvgLTV:",that.mAvgLTV)
        //that.html_mortgageSummary="<div># Records:" + that.mTotalPoints + "</div><div>LTV:" + that.mAvgLTV + "</div><div>Average Price:" + that.mAvgPrice+"</div>" + "<div>Average Loan:" + that.mAvgLoan +"</div>"
        var mortgageSummaryTable = `<div id='rcorners3' style='text-align: left;'><table class="popuptable">
  <tr >
    <td >Properties</td>
    <th>${that.mTotalPoints}</th>
  </tr>
  <tr >
    <tr >
    <td>Maturity Range</td>
    <th>${that.mMaturity}</th>
  </tr>
    <td>Total Loan $</td>
    <th >${that.mSumLoan}</th>
  </tr>
  <tr>
    <td >Total Price $</td>
    <th >${that.mSumPrice}</th>
  </tr>
  <tr >
  <td >Average Loan $</td >
  <th w>${that.mAvgLoan}</th> 
  </tr>
  <tr>
  <td >Average Price $</td>
  <th >${that.mAvgPrice}</th>
  </tr>
 
  <tr>
  <td>LTV %</td>
  <th>${that.mAvgLTV}</th>
  </tr>
    <tr>
  <td>LTAV %</td>
  <th>Not Available</th>
  </tr>
</table></font></div>`;

        that.html_mortgageSummary = mortgageSummaryTable;
      });
      tline.toggle();
      console.log("tline middle date:", tline.getDate("middle"));
      // Run on the timeline
      var running = false;
      var start = new Date("2023-12-30");
      var end = new Date("2024-01-05");
      function go(next) {
        var date = tline.getDate();
        if (running) clearTimeout(running);
        if (!next) {
          // stop
          if (date > start && date < end && running) {
            running = false;
            return;
          }
          if (date > end) {
            date = start;
          }
        }
        if (date > end) {
          return;
        }
        if (date < start) {
          date = start;
        }
        // 1 day
        date = new Date(date.getTime() + 24 * 60 * 60 * 1000);
        tline.setDate(date, { anim: false });
        // next
        running = setTimeout(function() {
          go(true);
        }, 100);
      }
      //go();
      //mortLegend.style.display = "block";

      console.log("screen mode:", this.$ons.orientation.isLandscape());
      this.$ons.orientation.on("change", function(e) {
        console.log("e.isPortrait:", e.isPortrait);
        mapconfig.mortgagevlayer.setVisible(
          !e.isPortrait && mortLegend.style.display == "block"
        );
        if (e.isPortrait) {
          summaryBox.style.display = "none";
          summaryTable.style.display = "none";
        }
      });
    },

    initPopup() {
      // Vienna marker
      popup = new Overlay({
        positioning: "bottom-center",
        element: this.$refs.popup,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
        //offset: [-140 + 120, 0],
      });
      console.log("popup", popup);
      map.addOverlay(popup);
    },
    initData() {},
    popupcloser() {
      popup.setPosition(undefined);
      //   closer.blur();
      return false;
    },
    MapOnClick(coordinate) {
      //pop up
      //var hdms = toStringHDMS(toLonLat(coordinate));

      //if( containsCoordinate(Perry_extent,coordinate) ) {

      var apopupContent = "";
      //this.popupContent=""
      var a_address = "";

      // Get the extent for map boundaries
      //var extent =map.getView().calculateExtent(map.getSize());
      //console.log("Extent, LL_x: " + extent[0]); //left LL_x: -13708573.09907316
      //console.log("Extent, LL_y: " + extent[1]); //bottom LL_y: 6317899.993763422
      //console.log("Extent, UR_x: " + extent[2]); //right UR_x: -13706121.72827147
      //console.log("Extent, UR_y: " + extent[3]); //top UR_y: 6319568.366776173

      var url = mapconfig.bcparcelmap
        .getSource()
        .getFeatureInfoUrl(
          coordinate,
          map.getView().getResolution(),
          map.getView().getProjection(),
          {
            INFO_FORMAT: "application/json",
            propertyName:
              "PARCEL_FABRIC_POLY_ID,PARCEL_NAME,PIN,PID_NUMBER,PID,OWNER_TYPE,PARCEL_CLASS,PLAN_NUMBER,SHAPE",
          }
        );
      console.log("url:" + url);
      url = proxyurl1 + url;

      // Ref: https://stackoverflow.com/questions/54042152/uncaught-in-promise-typeerror-cannot-set-property-playername-of-undefined-a/54042367
      let that = this; // this is needed as 'this' is not understood after axio.get.then(function)
      axios.get(url).then(function(response) {
        console.log(response);
        if (response.data.features.length > 0) {
          var feature = response.data.features[0];
          that.selectedParcel = feature;
          that.selectedParcel_id = feature.properties.PARCEL_FABRIC_POLY_ID;

          that.checkBookmarked();

          var g = feature.geometry; //get geometry of a featyre in your vector layer

          // Remove existing polygon
          map.getLayers().forEach((layer) => {
            if (layer && layer.get("name") === "Selected Location") {
              //alert("Remove Vector Layer");
              map.removeLayer(layer);
            }
          });

          // Set Parcel Color
          var parcelcolor = "blue";
          if (!feature.properties.PID) {
            //TO DO: GET ADDRESS from GEOCODER.CA e.g. http://geocoder.ca?latt=49.2631005&longt=-122.8852747&reverse=1&geoit=XML
            parcelcolor = "red";
          } else if (feature.properties.PARCEL_NAME == "Building Strata") {
            parcelcolor = "green"; // Build Strata but with a PID (one of the units) provided
          }

          that.lonlat = olProj.transform(coordinate, "EPSG:3857", "EPSG:4326");
          var lon = that.lonlat[0];
          var lat = that.lonlat[1];
          // Use Bing Map API
          //var BingMapKey =
          //  "AnBk-_PACFpRa99Sgep3ZAS0y1co3qI0NvMcEJTWURpLTOof56yvVxjKQ15JhB5m";
          //var BingMapAPIURL =
          //  "https://dev.virtualearth.net/REST/v1/Locations/";

          var IQURL =
            "https://us1.locationiq.com/v1/reverse.php?key=6cdf16a403410b&lat=" +
            lat +
            " &lon=" +
            lon +
            "&addressdetails=1&format=json";

          if (
            !feature.properties.PID ||
            feature.properties.PID == "Building Strata"
          ) {
            axios
              //   .get(BingMapAPIURL + lat + "," + lon + "?key=" + BingMapKey)
              .get(proxyurl1 + IQURL)
              .then(function(response) {
                console.log("Geocoding address", response);
                var mapAddress = response.data.address;
                if (mapAddress) {
                  var road = "";
                  var house_number = "";
                  var name = "";
                  if (mapAddress.road) {
                    road = mapAddress.road;
                  }
                  if (mapAddress.house_number) {
                    house_number = mapAddress.house_number;
                  }
                  if (mapAddress.name) {
                    name = mapAddress.name;
                  }

                  mapAddress = house_number + " " + road;

                  //  if (name.length>0) {mapAddress=name};
                  a_address = mapAddress;
                  that.selectedAddress = a_address;
                }
                /* for Bing Map API
                var result = response.data;
                let a_address =
                  result.resourceSets[0].resources[0].address.addressLine;
                */
                apopupContent = `<p>${a_address} <i>(to be confirmed)</i></p>`;
                that.popupContent = apopupContent;
                popup.setPosition(coordinate);
              });
          } else {
            var props = feature.properties;
            var PID = props.PARCEL_NAME;

            var aPID =
              PID.substr(0, 3) + "-" + PID.substr(3, 3) + "-" + PID.substr(6);
            that.GetParcelInfoByPID(PID).then(function() {
              if (that.$data.xProperty) {
                console.log("$data.xProperty", that.$data.xProperty);
                if (that.$data.xProperty) {
                  console.log(
                    "aProperty.Address:",
                    that.$data.xProperty.Address
                  );
                  apopupContent = `<p align="left">${
                    that.$data.xProperty.Address
                  }</p><p align="left">${that.$data.xOwner.FirstName1.concat(
                    " ",
                    that.$data.xOwner.LastName1
                  )}</p><p align="left">${that.$data.xOwner.Company}</p`;

                  that.popupContent = apopupContent;
                  popup.setPosition(coordinate);
                  that.selectedAddress = that.$data.xProperty.Address;
                  EventBus.$emit("get_coordinate", that.lonlat);

                  console.log("transform coordinate:", that.lonlat);

                  EventBus.$emit("get_property", that.$data.xProperty);
                  EventBus.$emit("get_owner", that.$data.xOwner);
                  EventBus.$emit("get_pid", that.$data.xPID);
                  EventBus.$emit(
                    "get_db-contactid",
                    that.$data.xOwner.db + "-" + that.$data.xOwner.id_contacts
                  );
                  that.ownerinfo = that.$data.xOwner;
                  that.propinfo = that.$data.xProperty;
                }
              } else {
                axios.get(proxyurl1 + IQURL).then(function(response) {
                  console.log("Geocoding address2", response);
                  var mapAddress = response.data.address;
                  if (mapAddress) {
                    var road = "";
                    var house_number = "";
                    var name = "";
                    if (mapAddress.road) {
                      road = mapAddress.road;
                    }
                    if (mapAddress.house_number) {
                      house_number = mapAddress.house_number;
                    }
                    if (mapAddress.name) {
                      name = mapAddress.name;
                    }

                    mapAddress = house_number + " " + road;

                    //  if (name.length>0) {mapAddress=name};
                    a_address = mapAddress;
                    that.selectedAddress = a_address;
                  }
                  EventBus.$emit("get_coordinate", that.lonlat);
                  console.log("transform coordinate:", that.lonlat);
                  EventBus.$emit("get_property", that.$data.xProperty);
                  EventBus.$emit("get_owner", that.$data.xOwner);
                  EventBus.$emit("get_pid", null);
                  EventBus.$emit(
                    "get_plannumber",
                    feature.properties.PLAN_NUMBER
                  );
                  that.ownerinfo = that.$data.xOwner;
                  that.propinfo = that.$data.xProperty;
                  that.popupContent = `<p align="left">${a_address} <i>(to be confirmed)</i></p><p align="left">${feature.properties.PLAN_NUMBER}</p>`;
                  popup.setPosition(coordinate);
                });
              }
            }); // end that.GetParcelInfoByPID
          } // end if else (feature.properties.PID)

          var styles = [
            new Style({
              stroke: new Stroke({
                color: parcelcolor,
                width: 3,
              }),
              fill: new Fill({
                color: "rgba(0, 0, 255, 0.1)",
              }),
            }),
          ];

          landMerger.getVertices(
            g.coordinates,
            feature.properties.PID,
            (vertices) => {
              console.log("vertices", vertices);
              var polygon = new Polygon(vertices);
              that.selectedCentre = polygon.getInteriorPoint();

              // fix for parcel selected but cannot get blue polygon redraw. e.g. 25450 lougheed Maple Ridge
              if (!that.selectedCentre.flatCoordinates[0]) {
                var aPoint = new Point(coordinate);
                that.selectedCentre = aPoint;
                console.log("aPoint", aPoint);
              }

              //polygon.transform('EPSG:4326', 'EPSG:3857');
              // Create feature with polygon.
              var afeature = new Feature(polygon);
              // Create vector source and the feature to it.
              var vectorSource = new Vector();
              vectorSource.addFeature(afeature);
              // Create vector layer attached to the vector source.
              var vectorLayer = new VectorLayer({
                name: "Selected Location",
                source: vectorSource,
                style: styles,
              });
              // Add the vector layer to the map.
              map.addLayer(vectorLayer);
            }
          ); // end of landMerger.getVertices
        } // end of if (feature)
      }); // end of get(URL)
      // }  //end of if perry extent
    }, // end of MapOnClick
    fileUpload: function(file, path = "/", callback) {
      if (!file) {
        alert("Please select a file to upload");
        return;
      }
      let extension = file.name.split(".").pop();
      let newfilename = this.currentUser.uid + "." + extension;
      let newfile = new File([file], newfilename);
      console.log("newfilename", newfile.name);
      let uploadTask = fb.storage
        .ref()
        .child(`images/${newfile.name}`)
        .put(newfile);
      uploadTask.on(
        "state_changed",
        function(snapshot) {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          callback(error, null);
        },
        (success) => {
          let downloadURL = uploadTask.snapshot.ref.getDownloadURL();
          callback && callback(null, downloadURL);
        }
      );
    },
    clickSumSidebar(e) {
      //console.log("e on sumsidebar:",e)
      if (e.target.type == "checkbox") {
        //console.log("e.target:",e.target.id,e.target.checked)

        if (e.target.checked == false) {
          this.selectedAssetType = this.selectedAssetType.filter(function(el) {
            return el !== e.target.value;
          });
          //console.log("selectedAssetType:", this.selectedAssetType);
          let that = this;
          mapconfig.mortgagevlayer
            .getSource()
            .getFeatures()
            .forEach(function(f) {
              //console.log("feature of mlayer in tline:",new Date(f.get('MatDate')).toUTCString())
              //console.log("e.target value:",e.target.value,f.get("InvNum").split("-")[1])
              if (f.get("AssetType") == e.target.value) {
                f.setStyle([]);
              }
            });
        } else if (e.target.checked == true) {
          this.selectedAssetType.push(e.target.value);
          //console.log("selectedAssetType:", this.selectedAssetType);
          var extent = map.getView().calculateExtent(map.getSize());
          var curStartDate = new Date(this.tline_sDate);
          var curEndDate = new Date(this.tline_eDate);
          mapconfig.mortgagevlayer
            .getSource()
            .getFeatures()
            .forEach(function(f) {
              //console.log("feature of mlayer in tline:",new Date(f.get('MatDate')).toUTCString())
              //console.log("e.target value:",e.target.value,f.get("InvNum").split("-")[1])
              var featureMatDate = new Date(f.get("MatDate"));
              if (f.get("AssetType") == e.target.value) {
                //if (containsExtent(extent,f.getGeometry().getExtent())){
                //console.log("MatDate:",featureMatDate,featureMatDate>= curStartDate)
                if (
                  featureMatDate >= curStartDate &&
                  featureMatDate <= curEndDate
                ) {
                  f.setStyle();
                }
                //}
              }
            });
        }
      }
    },
  }, // end of methods
  mounted() {
    this.$nextTick(() => {
      this.init(),
        EventBus.$on("delete_bookmark_parcel", (data) => {
          console.log("Need to delete this parcel in bookmarklayer:", data);
          this.deleteParcelonBookmarkLayer(data);
        }),
        EventBus.$on("go2map", (data) => {
          var polygonCoord = data;
          console.log("get go2map", polygonCoord);
          this.MapOnClick(polygonCoord);
          map.getView().setCenter(polygonCoord);
        });
    });
  },
};
</script>

<style>
#mapView {
  height: 94vh; /* make sure it does not trigger H scroll bar */
  width: 99vw; /* make sure it does not trigger H scroll bar */
  position: absolute;
  margin: 0;
  padding: 0;
  z-index: -1;
}

.ol-popup {
  position: absolute;
  background-color: #fff;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 250px;
  font-size: 14px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: #fff;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

.satellite {
  left: 0.5em;
  top: 7.5em;
}

.bookmark {
  left: 0.5em;
  top: 10em;
}

.locate {
  top: 12.5em;
  left: 0.5em;
}

.recentTrans {
  top: 15em;
  left: 0.5em;
}

.recentTrans-filter {
  top: 15em;
  left: 2.85em;
}

.ol-geocoder.gcd-txt-container {
  width: 15em;
  left: 3.3em;
}

.ol-geocoder .gcd-txt-control {
  height: 2.8em;
  top: 1.1em;
}

.mainmenu {
  position: absolute;
  top: 4em;
  left: 0.1em;
}

.testbutton {
  position: absolute;
  top: 70%;
  left: 0.1em;
  margin: 3px 0;
}

.avatar {
  vertical-align: middle;
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.ol-timeline {
  font-size: 2em;
  position: absolute;
  top: 90%;
}
.ol-timeline .ol-scroll {
  height: 2em;
}
.ol-timeline .ol-features {
  text-align: center;
}

.mortgage {
  left: 0.5em;
  top: 17.5em;
}

.mortLegend {
  position: absolute;
  text-align: left;
  top: 220%;
  left: 70em;
  right: 0.5em;
  width: 180px;
  background-color: white;
}
.summaryBox {
  left: 2.9em;
  top: 17.5em;
}
.summaryTable {
  position: absolute;
  text-align: left;
  top: -700%;
  left: 63.5em;
  right: 5.5em;
  width: 180px;
  background-color: white;
}

.popuptable {
  border-collapse: collapse;
}

.popuptable td,
.popuptable th {
  border: 1px solid #999;
  padding: 0.5rem;
  text-align: left;
}
caption {
  text-align: -webkit-left;
}
#rcorners2 {
  border-radius: 25px;
  border: 2px solid black;
  padding: 20px;
  width: 220px;
}
#rcorners3,
#rcorners4 {
  border-radius: 25px;
  border: 2px solid black;
  padding: 20px;
  width: 330px;
  margin-top: 20px;
}
#summarysidebar {
  border-radius: 25px;
  border: 2px solid black;
  padding: 20px;
  width: 360px;
  background-color: lightgray;
}
#db1 {
  display: none;
}
#summarysidebar {
  display: none;
}

@media (orientation: landscape) {
  .mortgage {
    display: block;
  }
}
@media (orientation: portrait) {
  .mortgage {
    display: none;
  }
  .ol-timeline {
    display: none;
  }
}
@media (orientation: portrait) {
  .summarysidebar {
    display: none;
  }
}

.sidebar {
  z-index: 9999;
  background-color: #f2f2f2;
  width: 20%;
  margin: 1% 1% 1% 1%;
  padding: 1% 1% 1% 1%;
  outline: none;
  border-style: solid;
  border-width: 3px;
  border-radius: 2px;
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}
.right {
  position: absolute;
  /* left: 63.5em; */
  right: 0.5em;
}


</style>
